import React from 'react';

import LightHeader from '../components/header/light-header';
import PageWrapper from '../components/page/page-wrapper';
import SEO from '../components/seo';

export default function NotFoundPage() {
  return (
    <PageWrapper>
      <SEO title="404: Not Found" />
      <LightHeader />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </PageWrapper>
  );
}
